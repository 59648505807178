import React, { useState } from "react";
import {
  Box,
  makeStyles,
  Button,
  Chip,
  Paper,
  Hidden,
} from "@material-ui/core";
import { default as DoneIcon } from "@material-ui/icons/Done";
import { Layout, main } from "helpers/MainLayout";
import MainContainer from "helpers/MainContainer";
import { makeText } from "helpers/i18n";
import { Subtitle, Body, Headline, Caption } from "helpers/text";
import { CSSTransition } from "react-transition-group";
import { useTimetable } from "helpers/timetable";
import { rgbaAlpha } from "helpers/theme";
import Linkify from "react-linkify";
import { ExternalLink } from "helpers/Link";

// ----------------------------------------------------------------
//     テキスト
// ----------------------------------------------------------------

const useText = makeText({
  ja: {
    title: "セッション",
    filterByTags: "タグで絞り込む",
    sessions: "件のセッション",
    readMore: "もっと見る",
  },
  en: {
    title: "Sessions",
    filterByTags: "Filter by tags",
    sessions: "Sessions",
    readMore: "Read More",
  },
});

// ----------------------------------------------------------------
//     各セッションのコンポーネント
// ----------------------------------------------------------------

type SessionProps = {
  title: string;
  author: string;
  description: string;
  category: string;
};

const gradationReadMore = (color: string): string => {
  const grad = [
    `${rgbaAlpha(color, "0")}`,
    `${rgbaAlpha(color, "0.7")} 50%`,
    color,
  ];
  return `linear-gradient(to bottom, ${grad.join(", ")})`;
};

const useSessionStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  descriptionFrame: {
    overflowY: "hidden",
    position: "relative",
    transition: "max-height 1s",
    "&.show-all-enter": { maxHeight: "1000px" },
    "&.show-all-enter-done": { maxHeight: "inherit" },
    "&.show-all-enter > .read-more": { display: "none" },
    "&.show-all-enter-done > .read-more": { display: "none" },
    "& > .read-more": {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
    },
    "& .read-more-top": {
      height: spacing(4),
      background: gradationReadMore(palette.background.paper),
    },
    [breakpoints.down("xs")]: {
      maxHeight: spacing(24),
    },
  },
  description: {
    whiteSpace: "pre-wrap",
    "& a": {
      color: palette.primary.main,
    },
  },
}));

const Session = React.memo(function Session(s: SessionProps) {
  const text = useText();
  const [showAll, setShowAll] = useState(false);
  const classNames = useSessionStyles();
  return (
    <Paper>
      <Box pt={2} mx={2}>
        <Headline color="secondary">{s.title}</Headline>
        <Box my={1}>
          <Caption color="textSecondary">{s.author}</Caption>
        </Box>
      </Box>
      <CSSTransition in={showAll} timeout={1000} classNames="show-all">
        <div className={classNames.descriptionFrame}>
          <Box mx={2} mb={2}>
            <Body className={classNames.description}>
              <Linkify
                componentDecorator={(href, text, key) => (
                  <ExternalLink key={key} href={href}>
                    {text}
                  </ExternalLink>
                )}
              >
                {s.description}
              </Linkify>
            </Body>
          </Box>
          <Hidden smUp>
            <div className="read-more helpers-hidden-at-first">
              <div className="read-more-top" />
              <Box
                px={1}
                py={1}
                bgcolor="background.paper"
                onClick={() => setShowAll(true)}
              >
                <Button color="primary">{text.readMore}</Button>
              </Box>
            </div>
          </Hidden>
        </div>
      </CSSTransition>
      <Box pb={2} mx={2}>
        <Chip label={s.category} variant="outlined" />
      </Box>
    </Paper>
  );
});

// ----------------------------------------------------------------
//     全体構成
// ----------------------------------------------------------------

const useAcceptedMainStyle = makeStyles(({ spacing }) => ({
  categoryListItem: {
    display: "inline-block",
    marginRight: spacing(1),
    marginTop: spacing(1),
  },
}));

const AcceptedMain: React.FC = () => {
  const text = useText();
  const classNames = useAcceptedMainStyle();
  const timetable = useTimetable();
  const { speakers, categories: allCategories } = timetable;
  const sessions = timetable.sessions
    .map((s, i) => ({ s, i, c: allCategories.of(s.categoryId) }))
    .sort(({ c: c1, i: i1 }, { c: c2, i: i2 }) =>
      c1.id !== c2.id ? c1.sort - c2.sort : i1 - i2
    )
    .map(({ s }) => s);

  const [categories, setCategories] = useState(
    new Map(
      allCategories.list.map((c) => [c.id, { name: c.name, filtered: false }])
    )
  );
  const filteredCategories = Array.from(categories.entries())
    .filter(([, category]) => category.filtered)
    .map(([id]) => id);
  const count =
    filteredCategories.length > 0
      ? sessions.filter((s) => filteredCategories.indexOf(s.categoryId) !== -1)
          .length
      : sessions.length;
  const filtered = filteredCategories.length > 0;

  return (
    <Layout title={text.title}>
      <MainContainer>
        <Box mt={5} mb={1}>
          <Subtitle singleLine>{text.filterByTags}</Subtitle>
        </Box>
        {Array.from(categories.entries(), ([id, category]) => (
          <div key={id} className={classNames.categoryListItem}>
            <Chip
              className="helpers-hidden-at-first"
              label={category.name}
              icon={category.filtered ? <DoneIcon /> : undefined}
              onClick={() => {
                const cs = new Map(categories);
                cs.set(id, { ...category, filtered: !category.filtered });
                setCategories(cs);
              }}
            />{" "}
          </div>
        ))}
        <Box my={3}>
          <Body color="textSecondary" align="center">
            {count} {text.sessions}
          </Body>
        </Box>
        <Box mb={5}>
          {sessions.map((s) => {
            const c = categories.get(s.categoryId);
            const visible = !filtered || (c && c.filtered);
            return (
              <Box key={s.title} mb={2} display={visible ? "block" : "none"}>
                <Session
                  title={s.title}
                  author={s.speakerIds
                    .map((s) => speakers.of(s).name)
                    .join(", ")}
                  description={s.description}
                  category={timetable.categories.of(s.categoryId).name}
                />
              </Box>
            );
          })}
        </Box>
      </MainContainer>
    </Layout>
  );
};

export default main(AcceptedMain);
